import { CategoryNames } from 'container/NewWidget/RightContainer/types';

export const categoryToSupport = [
	CategoryNames.Data,
	CategoryNames.DataRate,
	CategoryNames.Time,
	CategoryNames.Throughput,
	CategoryNames.Miscellaneous,
	CategoryNames.Boolean,
];
